<ul id="menu">
        <li data-menuanchor="page1" class="active"><a href="#Home">Home</a></li>
        <li data-menuanchor="page2"><a href="#Philosophy">Philosophy</a></li>
        <li data-menuanchor="page3"><a href="#AboutUs">About Us</a></li>
        <li data-menuanchor="page4"><a href="#WhyEdraj">Why Edraj</a></li>
        <li data-menuanchor="page5"><a href="#Vision">Vision</a></li>
        <li data-menuanchor="page6"><a href="#Products">Products & Commodities</a></li>
        <li data-menuanchor="page7"><a href="#Prices">Product Prices</a></li>
        <li data-menuanchor="page8"><a href="#Certificate">Certificate</a></li>
        <li data-menuanchor="page9"><a href="#ContactUs">Contact Us</a></li>
        <li data-menuanchor="page10"><a href="#Enquiry">Enquiry</a></li>
</ul>

<div id="pagepiling">
        <div class="section" id="section1">
                <video autoplay loop oncanplay="this.play()" onloadedmetadata="this.muted = true" id="myVideo">
                        <source src="../assets/videos/video.mp4" type="video/mp4">
                </video>
                <div class="elevatedLayer">
                        <div class="outerCover">
                                <div class="innerCover">

                                        <p class="bigIntroTop">
                                                Edraj
                                        </p>


                                </div>
                                <div class="innerCover">
                                        <p class="smallIntroText">
                                                International
                                        </p>


                                </div>
                                <div class="innerCover">
                                        <p class="smallIntroText">
                                                TRAD L.L.C
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <p class="smallIntroTextArabia">
                                                إدراج للتجـارة العالعالمية ش.م.م
                                        </p>

                                </div>

                                <div class="innerCover">

                                        <p class="bottomText">
                                                Integrity • Quality • Efficiency • Agility • Collaboration • Innovation
                                                •
                                                Commitment • Reliability • Safety • Trust
                                        </p>
                                </div>
                        </div>

                </div>
        </div>
        <div class="section" id="section2">
                <div class="intro">
                        <div class="outerCover">


                                <div class="innerCover">

                                        <p class="medIntroTop">
                                                Our Business Philosophy
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContent">
                                                Our philosophy is founded on a commitment to sustainable growth and
                                                development in an ever-evolving global trading market. Its objective is
                                                to provide quality of the highest order in its worldwide businesses.
                                                Loyalty, trust, professionalism and international expertise, reinforced
                                                by the ability and determination to foster long-term relationships, are
                                                the hallmarks of Edraj International Trading L.L.C.
                                        </p>


                                </div>

                                <div class="innerCover">
                                        <p class="sectionContent">
                                                <i class="bi bi-file-earmark-pdf-fill fs-5"><a class="linkDownloadWhite"
                                                                href="javascript:void(0)" style="cursor:pointer"
                                                                (click)="downloadPdf()">
                                                                Download Company profile
                                                        </a></i><i class="bi bi-arrow-down-circle"></i>
                                        </p>
                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section3">
                <div class="intro">
                        <div class="outerCoverShrunk">
                                <div class="innerCover">
                                        <p class="medIntroTop">
                                                About Us
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <p class="sectionContent">
                                                Enriched with our diverse international experience, Edraj
                                                International Trading L.L (Edraj) has enjoyed exceptional growth and
                                                success in the MENA region, Middle East and India. We have built a
                                                global network of clients, partners and associates and have firmly
                                                established ourselves as experienced traders in a diverse range of
                                                intermediate products and commodities from Oil, Gas, Petrochemicals,
                                                Metals and Minerals, Building Materials, Machinery and Spare Parts,
                                                Technology and Innovation to Agro-Commodities.
                                                The company has strived to fulfill the requirements of its
                                                partners who are mostly end manufacturers and end
                                                users. As the company’s
                                                partners
                                                grow in their
                                                respective businesses and
                                                expand through vertical
                                                integration, Edraj grows with them by helping them source the relevant
                                                raw materials, product s and commodities.
                                        </p>


                                </div>
                                <div class="innerCover">
                                        <p class="sectionContent">
                                                <i class="bi bi-file-earmark-pdf-fill fs-5"><a class="linkDownload"
                                                                href="javascript:void(0)" style="cursor:pointer"
                                                                (click)="downloadPdf()">
                                                                Download Company profile
                                                        </a></i><i class="bi bi-arrow-down-circle"></i>
                                        </p>
                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section4">
                <video autoplay loop oncanplay="this.play()" onloadedmetadata="this.muted = true" id="myVideoInline">
                        <source src="../assets/videos/fast_lane.mp4" type="video/mp4">
                </video>
                <div class="intro">
                        <div class="outerCoverShrunkRevColr">
                                <div class="innerCover">
                                        <p class="medIntroTop">
                                                Why Edraj International Trading L.L.C (Edraj)?
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <p class="sectionContent">
                                                At Edraj, we strongly uphold our corporate values and passionately
                                                conduct our business in a highly ethical manner t h at promotes free
                                                enterprise, excellence and competitiveness.
                                                Edraj International Trading L.L.C business is dominated by several key
                                                products and this will present opportunities For Edraj to explore new
                                                markets to source and to sell.
                                                The company has strived to f u l f i l the requirements of its partners
                                                who are mostly end manufacturers and end users. As the company’s
                                                partners grow in their respective businesses and experience and through
                                                vertical integration, Edraj grows with them by helping them to source
                                                the relevant raw materials, products and commodities.
                                                We don’t chase Money; we chase Solutions to Problems…..
                                        </p>
                                </div>
                                <div class="innerCover">
                                        <p class="sectionContent">
                                                <i class="bi bi-file-earmark-pdf-fill fs-5"><a class="linkDownloadWhite"
                                                                href="javascript:void(0)" style="cursor:pointer"
                                                                (click)="downloadPdf()">
                                                                Download Company profile
                                                        </a></i><i class="bi bi-arrow-down-circle"></i>
                                        </p>
                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section5">
                <video autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true"
                        id="myVideoInline">
                        <source src="../assets/videos/ageement.mp4" type="video/mp4">
                </video>
                <div class="intro">
                        <div class="outerCoverShrunk">
                                <div class="innerCover">
                                        <p class="medIntroTop highlight highlight-shadow">
                                                Vision
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <p class="sectionContent highlight highlight-shadow">

                                                To play a significant role and preserve the integrity of trading in the
                                                global market as well as become the preferred provenance for customers
                                                and suppliers incurring commodities, projects and services.
                                        </p>
                                </div>

                                <div class="innerCover">
                                        <p class="medIntroTop highlight highlight-shadow">
                                                Mission
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <p class="sectionContent highlight highlight-shadow">



                                                Edraj International Trading L.L.C. aspires to provide a wide portfolio
                                                of products and services. Confidently maintains q u al it y control and
                                                strive to obtain the most cost-effective solutions f or our valued
                                                Clients.

                                        </p>
                                </div>


                                <div class="innerCover">
                                        <p class="medIntroTop highlight highlight-shadow">
                                                Our Core Values
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <p class="sectionContent highlight highlight-shadow">
                                                Integrity, Quality, Efficiency, Agility, Collaboration, Innovation,
                                                Commitment, Reliability, Safety & Trust.
                                                We plant honesty and we receive your TRUST….
                                        </p>
                                </div>
                                <div class="innerCover">
                                        <p class="sectionContent highlight highlight-shadow">
                                                <i class="bi bi-file-earmark-pdf-fill fs-5"><a class="linkDownload"
                                                                href="javascript:void(0)" style="cursor:pointer"
                                                                (click)="downloadPdf()">
                                                                Download Company profile
                                                        </a></i><i class="bi bi-arrow-down-circle"></i>
                                        </p>
                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section6">

                <div class="intro">
                        <div class="outerCover">


                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                Intermediate Products of Oil & Gas
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContentSmall">

                                                • Crude Oil
                                                • Aviation Fuel
                                                • Gasoline
                                                • Gas Oil
                                                • Base Oil
                                                • Process Oil
                                                • Kerosene
                                                • Bitumen
                                                • Petrolatum
                                                • Paraffin Wax
                                                • Greases & Petroleum Coke
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                Metals & Minerals
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContentSmall">

                                                <span class="hideOnSmall">We trade in most of the industrial metals &
                                                        minerals (ferrous &
                                                        non-ferrous) listed below in b u l k cargo shipments, we offer
                                                        the
                                                        following.</span>
                                                • Coal
                                                • Carbon & Charcoal
                                                • Copper & Brass Product
                                                • Iron & Ferro Alloys and more
                                                • Construction Steel Items
                                                • GI Steels and Coils
                                                • Steel Billets
                                                • Aluminum
                                        </p>


                                </div>


                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                Agro Commodities
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContentSmall">

                                                <span class="hideOnSmall">We have a reputation f or being one of the
                                                        most reliable sources of
                                                        market intelligence thanks to our strong fundamental research on
                                                        trade
                                                        flows and our futures market analysis. Our insights have helped
                                                        us
                                                        deepen the customer relationships we have in key consumption
                                                        markets and
                                                        increase our volumes significantly. Edraj is one of the
                                                        international
                                                        pioneer trading companies of Agro commodities which
                                                        includes:</span>
                                                • Spices
                                                • Grains & Cereals
                                                • Pulses
                                                • Sugar
                                                • Rice
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                Building Materials
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContentSmall">

                                                <span class="hideOnSmall">Edraj is one of the international pioneer t
                                                        rad in g companies of
                                                        Building Materials which includes:</span>
                                                • Reinforced Steel Bars
                                                • Cement
                                                • Timber
                                                • GI Corrugated Sheets
                                                • Marbles & Granites
                                                • Polythene Sheets
                                                • F u l l range LED Lights
                                                • Specialty Glass Products – Japan
                                        </p>


                                </div>


                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                Chemicals & Petrochemicals
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContentSmall">

                                                <span class="hideOnSmall">Our chemicals & petrochemicals range
                                                        includes:</span>
                                                • Bitumen
                                                • Penetration Grade
                                                • Bitumen 40/ 50
                                                • Bitumen 60/ 70
                                                • Bitumen 85/100 or 80/100
                                                • Viscosity Grades
                                                • Bitumen VG30
                                                • Cut- Back (Medium Grades Cu ring)
                                                • Cut-Back MC-30/ Cut-Back MC-70/Cut-Back MC-250
                                                • Acrylonitrile Butadiene Styrene (ABS)
                                                • Crystal Melamine
                                        </p>


                                </div>




                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                Metal & Minerals
                                        </p>


                                </div>

                                <div class="innerCover">

                                        <p class="sectionContentSmall">
                                                PRIMARY ALUMINUM INGOTS
                                                - - SECONDARY ALUMINUM INGOTS
                                                - - SHREDDED AUTO CAST
                                                - - UBC (USED BEVERAGE CAN)
                                                - - FOIL SCRAP
                                                - - AUTOCAST SCRAP
                                                - - ALUMINA (AL203)
                                        </p>


                                </div>


                                <div class="innerCover">

                                        <p class="medIntroTopSmall">
                                                • Copper Ore
                                                • Iron Ore
                                                • ALLOY WHEEL SCRAP- INGOTS

                                                • Coal
                                                • DIAMONDS ROUGH & POLISHED
                                                • GOLD BULLION and DORE

                                        </p>


                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section7">

                <div class="intro">
                        <div class="outerCover container">


                                <div class="innerCover">

                                        <p class="medIntroTop">
                                                Indicative price of our Products
                                        </p>


                                </div>

                                <div class="innerCover row">

                                        <div class="table-responsive-sm " id="tblPrices">
                                                <table class="table table-dark table-striped table-sm">
                                                        <thead>
                                                                <tr>

                                                                        <th>No</th>
                                                                        <th>Commodities</th>
                                                                        <th>MOQ - IN MT</th>
                                                                        <th>MAX</th>
                                                                        <th>Origin</th>
                                                                        <th>Price</th>
                                                                        <th>Payment Term</th>

                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                <tr>
                                                                        <td>1</td>
                                                                        <td>ALUMINIUM(PRIMARY)</td>
                                                                        <td>1000 MT</td>
                                                                        <td>20,000 MT</td>
                                                                        <td>RUSSIA/AFRICA</td>
                                                                        <td>LME-3%</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>2</td>
                                                                        <td>ALUMINIUM(SECONDARY)</td>
                                                                        <td>200 MT</td>
                                                                        <td>5000 MT</td>
                                                                        <td>RUSSIA/AFRICA/SRILANKA</td>
                                                                        <td>LME-5%</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>3</td>
                                                                        <td>Copper - cathode</td>
                                                                        <td>1000 MT</td>
                                                                        <td>50,000 MT</td>
                                                                        <td>AFRICA</td>
                                                                        <td>LME-3%</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>4</td>
                                                                        <td>Copper Millberry</td>
                                                                        <td>500 MT</td>
                                                                        <td>30,000</td>
                                                                        <td>AFRICA/UK/RUSSIA</td>
                                                                        <td>LME-5%</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>5</td>
                                                                        <td>GOLD-DORE</td>
                                                                        <td>100 KGS</td>
                                                                        <td>8k~10k</td>
                                                                        <td>AFRICA</td>
                                                                        <td>LMBA-5%</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>6</td>
                                                                        <td>GOLD-BULLION</td>
                                                                        <td>500 KGS</td>
                                                                        <td>TBC</td>
                                                                        <td>HK/THAI/SWITZERLAND/FRANKFURT</td>
                                                                        <td>LMBA-3%</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>7</td>
                                                                        <td>HMS</td>
                                                                        <td>2000MT</td>
                                                                        <td>50,000MT</td>
                                                                        <td>UK/UAE/AFRICA</td>
                                                                        <td>TBC(320$ APPROX)</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>8</td>
                                                                        <td>IRON ORE</td>
                                                                        <td>100,000MT</td>
                                                                        <td>800,000MT</td>
                                                                        <td>AFRICA</td>
                                                                        <td>95$ FOB</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>9</td>
                                                                        <td>PET COKE</td>
                                                                        <td>TBC</td>
                                                                        <td>TBC</td>
                                                                        <td>GCC/RUSSIA</td>
                                                                        <td>TBC</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                                <tr>
                                                                        <td>10</td>
                                                                        <td>USED RAILS</td>
                                                                        <td>100K MT</td>
                                                                        <td>500K MT++</td>
                                                                        <td>KSA</td>
                                                                        <td>230$</td>
                                                                        <td>TRANSFERABLE LC</td>
                                                                </tr>
                                                        </tbody>
                                                </table>
                                        </div>
                                        <div class="innerCover">
                                                <p class="sectionContent">
                                                        <i class="bi bi-file-earmark-pdf-fill fs-5"><a
                                                                        class="linkDownloadWhite"
                                                                        href="javascript:void(0)" style="cursor:pointer"
                                                                        (click)="downloadPdf()">
                                                                        Download Product Prices
                                                                </a></i><i class="bi bi-arrow-down-circle"></i>
                                                </p>
                                        </div>


                                </div>


                        </div>
                </div>
        </div>
        <div class="section" id="section8">
                <div class="intro">
                        <div class="outerCoverShrunk">
                                <div class="innerCover">
                                        <p class="medIntroTopMedium">
                                                Certificate
                                        </p>
                                </div>

                                <div class="innerCover">

                                        <div>
                                                <img id="imgCert" src="../assets/images/certificate.png" alt="">
                                        </div>


                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section9">
                <div class="intro">
                        <div class="outerCoverShrunkBrown">
                                <div class="innerCover">
                                        <p class="medIntroTop">
                                                Contact Us
                                        </p>
                                </div>

                                <div class="innerCover">
                                        <p class="medIntroTopMedium">
                                                Address
                                        </p>
                                        <p class="sectionContent">
                                                Edraj International trading LLC.
                                                (CR No. 1337193)
                                                P.O. Box: 3521,
                                                Pin: 111
                                                Al Mawaleh South, SEEB
                                                Sultanate of Oman

                                        </p>

                                        <p class="medIntroTopMedium">
                                                Contact
                                        </p>
                                        <p class="sectionContent">

                                                Syed Almas Ali
                                        </p>
                                        <p class="sectionContent">
                                                Managing Director
                                        </p>
                                        <p class="sectionContent">
                                                <i class="bi bi-telephone-fill fs-5"></i>+968 91258407 / 95277127 (
                                                OMAN)
                                        </p>
                                        <p class="sectionContent">
                                                <i class="bi bi-telephone-fill fs-5"></i>+971 543297994 ( UAE)
                                        </p>
                                        <p class="sectionContent ">
                                                <i class="bi bi-telephone-fill fs-5"></i>+91 9040006000 ( IND)
                                        </p>
                                        <p class="medIntroTopMedium">
                                                Email
                                        </p>
                                        <p class="sectionContent">

                                                almas.edraj&#64;gmail.com
                                        </p>
                                </div>
                        </div>
                </div>
        </div>
        <div class="section" id="section10">
                <div class="intro">
                        <div class="outerCover">


                                <div class="innerCover">

                                        <p class="medIntroTop">
                                                Ask us
                                        </p>


                                </div>

                                <div class="innerCover">



                                        <form class="formMain" style="">
                                                <!-- <input id="name" name="name1" required minlength="4" [(ngModel)]="name1" #nameId="ngModel" placeholder="Name" style="width: 100%; height: 30px;font-size: 20px;">
                                        <br>
                                        <div *ngIf="nameId.invalid && (nameId.dirty || nameId.touched)">
                                        <div *ngIf="nameId?.errors.required" style="color: red;font-size: 20px;">
                                        Name is required.
                                        </div>
                                        <div *ngIf="nameId?.errors.minlength" style="color: red;font-size: 20px;">
                                        Name must be at least 4 characters long.
                                        </div>
                                        </div>
                                        <br><br> -->
                                                <!-- <mat-card>


                                                        <mat-form-field style="width: 100%">
                                                                <input matInput placeholder="Name"
                                                                        [formControl]="nameFormControl" />
                                                                <mat-error
                                                                        *ngIf="nameFormControl.hasError('minlength')">
                                                                        Name must be at least 4 characters long.
                                                                </mat-error>
                                                                <mat-error *ngIf="nameFormControl.hasError('required')">
                                                                        Name is <strong>required</strong>
                                                                </mat-error>
                                                        </mat-form-field>

                                                        <mat-form-field style="width: 100%">
                                                                <input matInput placeholder="Email"
                                                                        [formControl]="emailFormControl" />
                                                                <mat-error *ngIf="emailFormControl.hasError('email')">
                                                                        Please enter a valid email address
                                                                </mat-error>
                                                                <mat-error
                                                                        *ngIf="emailFormControl.hasError('required')">
                                                                        Email is <strong>required</strong>
                                                                </mat-error>
                                                        </mat-form-field>

                                                        <mat-form-field style="width: 100%">
                                                                <input matInput placeholder="Enquiry"
                                                                        [formControl]="enquiryFormControl" />
                                                                <mat-error *ngIf="enquiryFormControl.hasError('maxLength')">
                                                                        Allowed 100 chars
                                                                </mat-error>
                                                                <mat-error
                                                                        *ngIf="enquiryFormControl.hasError('required')">
                                                                        Enquiry field is <strong>required</strong>
                                                                </mat-error>
                                                        </mat-form-field>

                                                        <button mat-raised-button
                                                                [disabled]="emailFormControl.invalid || nameFormControl.invalid || loading"
                                                                color="primary" (click)="register()">
                                                                {{buttionText}}
                                                        </button>

                                                        <div class="alert alert-success labelStatus" #lblSuccess *ngIf="isSuccess" role="alert">
                                                                Enquiry sent <i class="bi bi-check-circle-fill"></i>
                                                        </div>

                                                        <div class="alert alert-danger labelStatus" #lblError *ngIf="isFailed" role="alert">
                                                                Error sending enquiry <i class="bi bi-x-circle-fill"></i>
                                                        </div>
                                                </mat-card> -->
                                        </form>
                                        <!-- <button [routerLink]="['/profile', 12367]">Go to Profile</button> -->
                                        <br />
                                        <div style="width: 50%; margin: auto">
                                                <div class="dinamic">
                                                        <!-- <button mat-raised-button color="primary" (click)="assignComponent('cow')">
                                        Cow
                                        </button>
                                        <button mat-raised-button color="primary" (click)="assignComponent('cat')">
                                        Cat
                                        </button>
                                        <button mat-raised-button color="primary" (click)="assignComponent('dog')">
                                        Dog
                                        </button> -->
                                                </div>

                                                <div>
                                                        <!-- <h1><ng-container *ngComponentOutlet="dummyComponent"></ng-container></h1> -->
                                                </div>
                                        </div>


                                </div>

                            
                        </div>
                </div>
        </div>
</div>